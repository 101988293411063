<template>
  <div>
    <div v-if="state.fileList.length > 0" class="file-item-box">
      <div v-for="(item, index) in state.fileList" :key="index" class="file-item">
        <img :src="getUrl(item, index)" alt="" class="file-img" />
        <div class="file-name-box">
          <a-tooltip v-if="item.name?.length > 15">
            <template #title>{{ item.name }}</template>
            <p class="file-name">{{ item.name }}</p>
          </a-tooltip>
          <p class="file-name" v-else>{{ item.name }}</p>
          <a-progress :percent="item.percent" :show-info="false" strokeColor="#3171F4" :strokeWidth="2" class="progress"
            v-if="item.status === 'uploading'" />
        </div>
        <p @click="onDelete(index, $attrs.id)">
          <IconFont type="icon-delete" />
        </p>
      </div>
    </div>
    <a-upload v-model:file-list="state.fileList" name="file" :action="state.action + '?width=88&height=88'"
      :show-upload-list="false" withCredentials :before-upload="beforeUpload" @change="handleChange">
      <a-button class="btn" v-if="state.fileList && state.fileList.length < state.count">
        <img style="margin-right: 4px; vertical-align: middle; transform: translateY(-1px);"
          src="@/assets/svg/upload.svg" />
        上传文件
      </a-button>
    </a-upload>
    <p class="tips">{{ state.uploadText || '（可上传图片、文档等类型文件，单个文件大小不能超过5M）' }}</p>
  </div>
</template>

<script>
import { defineComponent, reactive, computed, onUpdated, onMounted } from 'vue'
// import { Notification } from 'ant-design-vue'
import { cmsNotice } from '@/utils/utils'
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload'
export default defineComponent({
  emits: ['fileChange', 'uploading'],
  props: {
    fileList: {
      type: Array,
      default: () => []
    },
    count: Number,
    fileType: {
      type: Array,
      default: () => ['jpg', 'png', 'tif', 'jpeg', 'bmp', 'webp']
    },
    action: {
      type: String,
      default: action
    },
    size: {
      type: Number,
      default: 10
    },
    minSzieByte: {
      type: Number,
      default: 6
    },
    uploadText: String
  },
  setup(props, ctx) {
    console.log('props', props)
    const { emit } = ctx
    const state = reactive({
      fileList: [], // 图片list
      label: computed(() => props.label),
      action: computed(() => props.action), // 伤处路径
      count: computed(() => props.count), // 最大上传几个
      uploadText: computed(() => props.uploadText),
      size: computed(() => props.size), // 最大尺寸
      fileType: computed(() => props.fileType) // 上传文件类型 1
    })
    const getUrl = computed(() => (file, index) => {

      const list = [
        {
          type: ['docx', 'doc'],
          url: require('@/assets/images/word.png')
        },
        {
          type: ['xls', 'xlsx'],
          url: require('@/assets/images/excel.png')
        },
        {
          type: ['pdf'],
          url: require('@/assets/images/pdf.png')
        }
      ]
      let url
      const suffix = file.name.split('.').pop()
      if (['docx', 'doc', 'xls', 'xlsx', 'pdf'].includes(suffix)) {
        for (let index = 0; index < list.length; index++) {
          if (list[index].type.includes(suffix)) {
            url = list[index].url
          }
        }
      } else if (file.response) {
        return file.response?.data[0].fullUrl
      }
      else {
        return file.fullFile
      }
      // if (!url) {
      //   if (file.url) return file.url
      //   // 检查 file.originFileObj 是否是一个 Blob 对象
      //   if (file.originFileObj instanceof Blob) {
      //     return URL.createObjectURL(file.originFileObj)
      //   } else {
      //     // 如果 file.originFileObj 不是 Blob 对象，则返回一个空字符串或其他默认值
      //     return ''
      //   }
      // }
      return url
    })
    const onDelete = (index, id) => {
      state.fileList.splice(index, 1)
      emit('fileChange', 'add', state.fileList)
      emit('blur')
    }
    const beforeUpload = file => {
      return new Promise((resolve, reject) => {
        const suffix = file.name.split('.').pop()
        const size = Math.floor(file.size / (1024 * 1024))
        let type = state.fileType
        if (!state.fileType) type = ['jpg', 'png', 'tif', 'jpeg', 'bmp']
        if (state.size && size > state.size) {
          // Notification['error']({
          //   message: '提示',
          //   description: `大小不能超过${state.size}M`
          // })
          cmsNotice('error', `大小不能超过${state.size}M`)
          return reject(false)
        }
        if (file.size <= props.minSzieByte) {
          console.log(file.size, 'size')
          cmsNotice('error', `大小应大于${props.minSzieByte}个字节`)
          return reject(false)
        }
        if (!type.includes(suffix)) {
          // Notification['error']({
          //   message: '提示',
          //   description: `格式错误，仅支持${type.join(',')}格式`
          // })
          cmsNotice('error', `格式错误，仅支持${type.join(',')}格式`)
          return reject(false)
        }
        return resolve(true)
      })
    }
    const handleChange = info => {
      console.log('info', info)
      try {
        const {
          file: { status, response }
        } = info
        switch (status) {
          case 'uploading':
            emit('uploading', true)
            break
          case 'done':
            // 处理上传成功
            emit('uploading', false)
            if (response && response.code === 0) {
              console.log('state.fileList', state.fileList)
              emit('fileChange', 'add', state.fileList)
            }
            break
          case 'error':
            // 处理错误
            emit('uploading', false)
            emit('fileChange', 'error', state.fileList)
            state.fileList = state.fileList.slice(0, -1);
            break
        }
      } catch (error) {
        emit('fileChange', 'error', state.fileList)
      }
    }

    const transformFile = file => {
      const fileData = new FormData()
      fileData.append('width', 120)
      fileData.append('file', file)
      fileData.append('height', 120)
      console.log(file)
      return fileData
    }
    onMounted(() => {
      state.fileList = props.fileList
    })
    onUpdated(() => {
      console.log('props.fileList', props.fileList)
      if (props.fileList.length && !state.fileList.length) {
        state.fileList = props.fileList
      }
    })

    return {
      beforeUpload,
      handleChange,
      state,
      transformFile,
      getUrl,
      onDelete
    }
  }
})
</script>

<style lang="scss" scoped>
.progress {
  width: 200px;
}

.btn {
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  color: #666666;

  &:hover {
    color: #666666;
  }
}

.tips {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  width: 550px;
  // margin-top: -20px;
}

.file-item-box {
  max-height: 348px;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 100%;
    display: block;
    overflow-y: scroll;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 51, 0.1);
    border-radius: 2px;
  }
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;

  .file-img {
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }

  .file-name-box {
    width: 224px;

    .file-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }

  p {
    width: 26px;
    text-align: center;
  }
}

.file-item:hover {
  background: #f5f5f5;
}
</style>
