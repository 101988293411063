<template>
  <a-form layout="vertical" ref="formRef" :model="modelState" :rules="rulesRef" class="drawe-form form-add">
    <div class="form-main" :style="{ paddingBottom: props.title === '归档' ? '24px' : '0' }">
      <a-form-item label="文件类型" name="selectFile" class="drawe-form-item">
        <a-select
          v-model:value="modelState.selectFile"
          allowClear
          class="form-select"
          placeholder="请选择文件类型"
          style="width: 500px;"
          :filter-option="filterOption"
          @change="changeFile"
        >
          <a-select-option
            v-for="(value, key) in FlieList"
            :key="key"
            :value="[value.typeName, value.id, value.processId, value.enterpriseId]"
          >
            <div class="name-box">
              <div class="company-name">{{ value.typeName }}</div>
            </div>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="用印后文件" name="archiveFile" class="drawe-form-item">
        <div style="width: 300px">
          <fileUpload
            :fileList="modelState.archiveFile"
            :count="100"
            :action="action"
            :size="50"
            class="file-upload"
            @fileChange="handleThumbUploadChange"
            @uploading="uploading"
            :fileType="['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'webp', 'xls', 'xlsx', 'doc', 'docx']"
            uploadText="(可上传图片,word,pdf,Excel等文件类型,单个文件大小不超过50MB)"
          />
        </div>
      </a-form-item>
      <a-form-item label="归档备注" name="remark" class="remark drawe-form-item">
        <a-textarea
          :autoSize="{ minRows: 5, maxRows: 5 }"
          v-model:value="modelState.remark"
          placeholder="请输入归档备注"
          :maxlength="500"
          showCount
          class="opinion-textarea"
          style="width: 500px;"
        />
      </a-form-item>
      <a-form-item label="补充附件" name="replenishFile">
        <div style="width: 300px">
          <fileUpload
            :fileList="modelState.replenishFile"
            :count="100"
            :action="action"
            :size="50"
            class="file-upload"
            @fileChange="replenishFileUploadChange"
            @uploading="uploading"
            :fileType="['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'webp', 'xls', 'xlsx', 'doc', 'docx']"
            uploadText="(可上传图片,word,pdf,Excel等文件类型,单个文件大小不超过50MB)"
          />
        </div>
      </a-form-item>
      <div
        class="approve"
        v-if="approvalInfo?.length > 0"
        :style="{ paddingBottom: props.title === '归档' ? '12px' : '0' }"
      >
        <p class="title">审批信息</p>
        <section class="approval-flow-box">
          <ApprovalFlow :approvalInfo="approvalInfo" :isApprove="false" />
        </section>
      </div>
    </div>
    <div class="footer" v-if="props.footer">
      <a-button @click="close()" class="MyCancel" >取消</a-button>
      <a-button type="primary" @click="handleOk" class="footer-ok MySubmit">提交</a-button>
    </div>
  </a-form>
</template>
<script setup>
import { ref, defineProps, reactive, createVNode, defineEmits, onMounted, defineExpose } from 'vue'
import { CloseOutlined } from '@ant-design/icons-vue'
import fileUpload from '@/components/Upload/fileUpload'
import '@/style/page-add.scss'
import { Modal } from 'ant-design-vue'
import { ExclamationOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
import { getAllType, submitArchive, getArchiveVetting, reSubmitArchive, getBindProcessType } from '@/apis/archive'
import ApprovalFlow from '@/components/ApprovalFlow/index.vue'
import { useRouter } from 'vue-router'
const emits = defineEmits(['close'])
const modelState = reactive({ selectFile: [], archiveFile: [], remark: '', replenishFile: [] })
const formRef = ref()
const router = useRouter()
const props = defineProps([
  'visible',
  'closevisible',
  'title',
  'footer',
  'height',
  'archiveRecord',
  'documentId',
  'recordinfo'
])
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload'
const FlieList = ref([])
const Loading = ref(false)
const approvalInfo = ref([])
const thefileId = ref('')
const rulesRef = reactive({
  selectFile: [
    {
      required: true,
      message: '请选择文件类型',
      type: 'array'
    }
  ],
  archiveFile: [
    {
      required: true,
      message: '请上传用印后文件',
      trigger: 'change',
      type: 'array'
    }
  ]
})
const modelRef = ref()
const filterOption = (input, option) => {
  console.log('input', input)
  console.log('option', option.label)
  return option.label.indexOf(input) > -1
}
const changeFile = e => {
  console.log('改变文件类型', e)
  let params = {
    processId: e[2]
  }
  getArchiveVetting(params).then(res => {
    console.log('审批信息', res)
    if (res.success) {
      approvalInfo.value = res.data
    }
  })
}

onMounted(async () => {
  console.log('当前归档记录info', props.recordinfo?.ableReSubmitArchive)
  if (props.archiveRecord?.ableReSubmitArchive) {
    //普通用印重新归档
    await reArchive(props.archiveRecord.documentId)
  } else if (props.recordinfo?.ableReSubmitArchive) {
    // 特权人用印重新归档
    await reArchive(props.documentId)
  } else {
    getFile()
  }
})

const reArchive = documentId => {
  let params = { isReApply: true, documentId }
  reSubmitArchive(params).then(res => {
    console.log('重新提交归档申请', res)
    if (res.success) {
      let data
      data = res.data.documentArchiveDetailsVO?.documentArchiveDTO
      thefileId.value = data?.fileTypeId
      // modelState.selectFile[0] = data.fileTypeName
      console.log('data.fileTypeId', thefileId.value)
      getFile()
      // modelState.selectFile[2] = data.processId
      if (res.data.hasArchive) {
        console.log('重新归档的时候流程删除或暂停', res.data.hasArchive)
        modelState.selectFile = []
        cmsNotice('warning', '文件类型已变更，请重新选择')
      }
      approvalInfo.value = res.data.archiveFlowNodes
      modelState.remark = res.data.documentArchiveDetailsVO?.documentArchiveRemarkList[0]?.remark
      modelState.archiveFile = res.data.documentArchiveDetailsVO?.documentArchiveFileList.map(item => {
        return { name: `${item.fileName}.${item.suffix}`, fileId: item.fileId, fullFile: item.fullFile }
      })
      modelState.replenishFile = res.data.documentArchiveDetailsVO?.documentArchiveAttachmentList.map(item => {
        return { name: `${item.fileName}.${item.suffix}`, fileId: item.fileId, fullFile: item.fullFile }
      })
    }
  })
}

const getFile = () => {
  let params = { bindProcess: true }
  console.log('fileId.value', thefileId.value)
  FlieList.value = []
  getBindProcessType(params).then(res => {
    console.log('已绑定归档流程的文件', res)
    if (res.success) {
      FlieList.value = res.data
      res.data.map(item => {
        if (item.id == thefileId.value) {
          modelState.selectFile = [item.typeName, item.id, item.processId]
        }
      })
    }
  })
}
//用印后文件
const handleThumbUploadChange = (type, res) => {
  if (type == 'add') {
    console.log('接受的组件的上传文件的值', res)
    // 上传附件成功后的赋值
    // modelRef.archiveFile = res.map(item => ({ fileId: item.response.data[0].id }))
    modelState.archiveFile = res.map(function(item, index) {
      console.log('res.item', item, item.response)
      if (item?.uid) {
        item = { name: item.name, fileId: item.response.data[0].id }
      }
      return item
    })
    formRef.value.clearValidate('archiveFile')
    console.log('上传文件modelRef.documentArchiveFile的值', modelState.archiveFile)
  }
  if (type == 'remove' || type == 'error') {
    cmsNotice('error', '文件上传失败，请重新上传')
  }
}
//补充附件
const replenishFileUploadChange = (type, res) => {
  console.log('接受的组件的上传文件的值', res)
  // 上传附件成功后的赋值
  // modelRef.archiveFile = res.map(item => ({ fileId: item.response.data[0].id }))
  modelState.replenishFile = res.map(function(item, index) {
    console.log('res.item', item)
    if (item?.uid) {
      item = { name: item.name, fileId: item.response.data[0].id }
    }

    return item
  })
  console.log('上传附件modelRef.documentArchiveFile的值', modelState.replenishFile)
}

//检测文件是否还在上传
const uploading = res => {
  Loading.value = res
  console.log('当前是否还在上传', res)
}
//关闭弹窗
const close = () => {
  ;(modelState.selectFile = []), (modelState.archiveFile = []), (modelState.replenishFile = [])
  modelState.remark = ''
  console.log('关闭了弹窗', modelState)
  props.closevisible()
  emits('close')
}

const handleOk = async () => {
  if (Loading.value) {
    cmsNotice('warning', '文件正在上传中，请稍后')
    return
  }
  console.log('看看modelState', modelState)
  formRef.value
    .validate()
    .then(() => {
      // console.log('values', modelState, toRaw(modelState));
      Modal.confirm({
        title: '是否归档！',
        icon: createVNode(ExclamationOutlined),
        content: '归档审批后,用印文件仅支持档案管理员查看,用印内容依旧支持查看',
        okText: '确定',
        cancelText: '取消',
        centered: true,
        onCancel() {},
        async onOk() {
          let params = {
            fileTypeId: Number(modelState.selectFile[1]), //文件类型id
            processId: Number(modelState.selectFile[2]), //流程id
            remark: modelState.remark, //备注
            annexFileIds: modelState.replenishFile?.map(item => item.fileId), //附件
            fileIds: modelState.archiveFile?.map(item => item.fileId) //用印后文件
          }
          if (props.archiveRecord) {
            params.documentId = props.archiveRecord.documentId //文件申请表id
          } else if (props.documentId) {
            params.documentId = props.documentId
          }
          console.log(params)
          await submitArchive(params).then(res => {
            console.log('归档申请提交成功', res)
            if (res.success) {
              cmsNotice('success', '归档申请提交成功')
              close()
              if (!props.archiveRecord?.fingerprint) {
                router.push({
                  path: '/seal/applyCompleteList'
                })
              }
              return
            }
          })
        }
      })
    })
    .catch(error => {
      console.log('error', error)
    })
  // console.log('提交', modelState)
}

defineExpose({
  handleOk
})
</script>
<style lang="scss" scoped>
.drawe-form {
  .form-main {
    padding-right: 0;
    height: 100%;
    overflow: scroll;
    padding: 24px;
    .drawe-form-item {
      padding-bottom: 0px;
    }
    .file-upload {
      :deep(.ant-upload, .img-area) {
        height: 36px;
      }
    }
  }

  .form-select {
    width: 500px !important;
  }

  .add-foot {
    width: calc(100vw - 208px);
    padding: 12px 24px;
    text-align: right;
    position: fixed;
    bottom: 0;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
  }

  .form-item {
    position: fixed;
    bottom: 0;
    height: 52px;
    width: 100%;
    background-color: #fff;
  }

  // .footer {
  //   // width: 100%;
  //   border-top: 1px solid #ebedf0;
  //   background-color: #fff;
  //   padding: 10px 24px;
  //   text-align: right;
  //   position: absolute;
  //   bottom: 0;

  //   .footer-ok {
  //     margin-left: 8px;
  //   }
  // }

  .approve {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0 24px 0px;
    width: 550px;
    margin-left: -24px;

    .title {
      margin-top: 12px;
      // margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }

    .prompt {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 20px;
    }

    .reminder {
      color: rgba(255, 147, 0, 1);
    }

    .approval-flow-box {
      width: 485px;
      overflow: scroll;
    }
  }

  .remark {
    // :deep(.ant-input-textarea-show-count::after) {
    //   position: relative;
    //   // top: -30px;
    //   right: 10px;
    // }
    :deep(.opinion-textarea) {
      position: relative;
      &::after {
        position: absolute;
        bottom: 10px;
        right: 11px;
      }
    }
  }

  .footer {
    bottom: 0;
    position: fixed;
    bottom: 0;
    // right: 0;
    display: flex;
    // width: 550px
    border-top: 1px solid #ebedf0;
    background-color: #fff;
    padding: 10px 24px;
    padding-left: 388px;

    .footer-ok {
      margin-left: 8px;
    }
    .MySubmit{
      background:#0A7BFF ;
      border-color: #0A7BFF !important;
    }
  }
}
</style>
<style lang="scss">
.ant-drawer-wrapper-body {
  .ant-drawer-header {
    position: absolute;
    background: white;
    top: 0;
    z-index: 999;
    width: 100%;
  }
}
</style>
